<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose" :title="formTitle">
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="所属机构" prop="deptId">
        <a-tree-select
          v-model="form.deptId"
          style="width: 100%"
          :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
          :tree-data="deptOptions"
          placeholder="请选择"
          :replaceFields="replaceFields"
          tree-default-expand-all
        >
        </a-tree-select>
      </a-form-model-item>
      <a-form-model-item label="工号" prop="jobNumber">
        <a-input v-model="form.jobNumber" placeholder="请输入工号" />
      </a-form-model-item>
      <a-form-model-item label="姓名" prop="name">
        <a-input v-model="form.name" placeholder="请输入姓名" />
      </a-form-model-item>
      <a-form-model-item label="身份证" prop="identityCard">
        <a-input v-model="form.identityCard" placeholder="请输入身份证" />
      </a-form-model-item>
      <a-form-model-item label="性别" prop="sex">
        <a-radio-group disabled v-model="form.sex" button-style="solid">
          <a-radio-button v-for="(d, index) in sexOptions" :key="index" :value="d.dictValue">{{
            d.dictLabel
          }}</a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="生日" prop="birthday">
        <a-date-picker
          placeholder="输入身份证后获取"
          disabled
          style="width: 100%"
          v-model="form.birthday"
          value-format="YYYY-MM-DD HH:mm:ss"
          format="YYYY-MM-DD"
          allow-clear
        />
      </a-form-model-item>
      <a-form-model-item label="民族" prop="nation">
        <a-input v-model="form.nation" placeholder="请输入民族" />
      </a-form-model-item>
      <a-form-model-item label="婚姻" prop="marriage">
        <a-radio-group v-model="form.marriage" button-style="solid">
          <a-radio-button v-for="(d, index) in marriageOptions" :key="index" :value="d.dictValue">{{
            d.dictLabel
          }}</a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="政治面貌" prop="politics">
        <a-select placeholder="请选择政治面貌" v-model="form.politics">
          <a-select-option v-for="(d, index) in politicsOptions" :key="index" :value="d.dictValue">{{
            d.dictLabel
          }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="首次参加工作时间" prop="firstWorkingTime">
        <a-date-picker
          style="width: 100%"
          v-model="form.firstWorkingTime"
          value-format="YYYY-MM-DD HH:mm:ss"
          format="YYYY-MM-DD"
          allow-clear
        />
      </a-form-model-item>
      <a-form-model-item label="在职状态" prop="workState">
        <a-select placeholder="请选择在职状态" v-model="form.workState">
          <a-select-option v-for="(d, index) in workStateOptions" :key="index" :value="d.dictValue">{{
            d.dictLabel
          }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="离职退休日期" prop="retirementTime">
        <a-date-picker
          style="width: 100%"
          v-model="form.retirementTime"
          value-format="YYYY-MM-DD HH:mm:ss"
          format="YYYY-MM-DD"
          allow-clear
        />
      </a-form-model-item>
      <a-form-model-item label="用工类型" prop="workType">
        <a-radio-group v-model="form.workType" button-style="solid">
          <a-radio-button v-for="(d, index) in workTypeOptions" :key="index" :value="d.dictValue">{{
            d.dictLabel
          }}</a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="排序号" prop="sort">
        <a-input v-model="form.sort" placeholder="请输入排序号" />
      </a-form-model-item>
      <a-form-model-item label="描述" prop="des">
        <a-input v-model="form.des" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="照片" prop="employeeImage">
        <file-upload v-model="form.employeeImage" :folder="params.folder" type="image"></file-upload>
      </a-form-model-item>
      <a-form-model-item label="是否怀孕" prop="pregnantStatus">
        <a-radio-group v-model="form.pregnantStatus" button-style="solid">
          <a-radio-button v-for="(d, index) in pregnantStatusOptions" :key="index" :value="d.dictValue">{{
            d.dictLabel
          }}</a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="居住情况-原籍" prop="nativePlace">
        <a-input v-model="form.nativePlace" placeholder="请输入居住情况-原籍" />
      </a-form-model-item>
      <a-form-model-item label="居住情况-现籍" prop="currentPlace">
        <a-input v-model="form.currentPlace" placeholder="请输入居住情况-现籍" />
      </a-form-model-item>
      <a-form-model-item label="居住情况-居住地" prop="habitation">
        <a-input v-model="form.habitation" placeholder="请输入居住情况-居住地" />
      </a-form-model-item>
      <a-form-model-item label="联系方式-手机号" prop="phone">
        <a-input v-model="form.phone" placeholder="请输入联系方式-手机号" />
      </a-form-model-item>
      <a-form-model-item label="联系方式-办公室电话" prop="officePhone">
        <a-input v-model="form.officePhone" placeholder="请输入联系方式-办公室电话" />
      </a-form-model-item>
      <a-form-model-item label="联系方式-邮箱" prop="email">
        <a-input v-model="form.email" placeholder="请输入联系方式-邮箱" />
      </a-form-model-item>
      <a-form-model-item label="联系方式-内部邮件" prop="internalMail">
        <a-input v-model="form.internalMail" placeholder="请输入联系方式-内部邮件" />
      </a-form-model-item>
      <a-form-model-item label="联系方式-紧急联系人" prop="emergencyContact">
        <a-input v-model="form.emergencyContact" placeholder="请输入联系方式-紧急联系人" />
      </a-form-model-item>
      <a-form-model-item label="联系方式-紧急联系人电话" prop="emergencyContactPhone">
        <a-input v-model="form.emergencyContactPhone" placeholder="请输入联系方式-紧急联系人电话" />
      </a-form-model-item>
      <a-form-model-item label="教育背景-毕业学校" prop="graduateSchool">
        <a-input v-model="form.graduateSchool" placeholder="请输入教育背景-毕业学校" />
      </a-form-model-item>
      <a-form-model-item label="教育背景-学历" prop="education">
        <a-select placeholder="请选择教育背景-学历" v-model="form.education">
          <a-select-option v-for="(d, index) in educationOptions" :key="index" :value="d.dictValue">{{
            d.dictLabel
          }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="用工情况-入职时间(司龄)" prop="hiredate">
        <a-date-picker
          style="width: 100%"
          v-model="form.hiredate"
          value-format="YYYY-MM-DD HH:mm:ss"
          format="YYYY-MM-DD"
          allow-clear
        />
      </a-form-model-item>
      <a-form-model-item label="用工情况-职位(职能)" prop="position">
        <a-select placeholder="请选择用工情况-职位(职能)" v-model="form.position">
          <a-select-option v-for="(d, index) in positionOptions" :key="index" :value="d.dictValue">{{
            d.dictLabel
          }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="用工情况-职务(职级)" prop="rank">
        <a-select placeholder="请选择用工情况-职务(职级)" v-model="form.rank">
          <a-select-option v-for="(d, index) in rankOptions" :key="index" :value="d.dictValue">{{
            d.dictLabel
          }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="用工情况-当前职级获取时间" prop="rankObtainTime">
        <a-date-picker
          style="width: 100%"
          v-model="form.rankObtainTime"
          value-format="YYYY-MM-DD HH:mm:ss"
          format="YYYY-MM-DD"
          allow-clear
        />
      </a-form-model-item>
      <a-form-model-item label="用工情况-社保号" prop="socialSecurityNumber">
        <a-input v-model="form.socialSecurityNumber" placeholder="请输入用工情况-社保号" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getEmployeeAccount, addEmployeeAccount, updateEmployeeAccount } from '@/api/iot/employeeAccount'
import { transformIdentityCard } from '@/utils/util'
import { TreeSelect } from 'ant-design-vue'

import { treeselect } from '@/api/system/dept'
export default {
  name: 'CreateForm',
  props: {
    sexOptions: {
      type: Array,
      required: true
    },
    marriageOptions: {
      type: Array,
      required: true
    },
    politicsOptions: {
      type: Array,
      required: true
    },
    workStateOptions: {
      type: Array,
      required: true
    },
    workTypeOptions: {
      type: Array,
      required: true
    },
    pregnantStatusOptions: {
      type: Array,
      required: true
    },
    educationOptions: {
      type: Array,
      required: true
    },
    positionOptions: {
      type: Array,
      required: true
    },
    rankOptions: {
      type: Array,
      required: true
    },
    statusOptions: {
      type: Array,
      required: true
    }
  },
  components: {
    ATreeSelect: TreeSelect
  },
  data() {
    return {
      replaceFields: { children: 'children', title: 'label', key: 'id', value: 'id' },
      loading: false,
      formTitle: '',
      // 表单参数
      form: {
        image: null,
        id: null,
        deptId: null,
        jobNumber: null,
        name: null,
        sex: '0',
        birthday: null,
        identityCard: null,
        nation: null,
        marriage: '0',
        politics: null,
        firstWorkingTime: null,
        workState: null,
        retirementTime: null,
        workType: '0',
        sort: null,
        des: null,
        employeeImage: null,
        pregnantStatus: '0',
        nativePlace: null,
        currentPlace: null,
        habitation: null,
        phone: null,
        officePhone: null,
        email: null,
        internalMail: null,
        emergencyContact: null,
        emergencyContactPhone: null,
        graduateSchool: null,
        education: null,
        hiredate: null,
        position: null,
        rank: null,
        rankObtainTime: null,
        socialSecurityNumber: null
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        deptId: [{ required: true, message: '所属机构不能为空', trigger: 'blur' }],
        jobNumber: [{ required: true, message: '工号不能为空', trigger: 'blur' }],
        name: [{ required: true, message: '姓名不能为空', trigger: 'blur' }],
        sex: [{ required: true, message: '性别不能为空', trigger: 'blur' }],
        birthday: [{ required: true, message: '生日不能为空', trigger: 'blur' }],
        identityCard: [
          { required: true, message: '身份证不能为空', trigger: 'blur' },
          {
            validator: (rule, value, cbfn) => {
              var reg = /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{7}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}$)/
              if (!reg.test(value)) {
                cbfn('请输入正确身份证号')
              }
              this.form.birthday = transformIdentityCard(value).birthday
              console.log('this.form.birthday', this.form.birthday)
              this.form.sex = transformIdentityCard(value).sex + ''
              this.$forceUpdate()
              cbfn()
            },
            trigger: 'blur'
          }
        ],
        nation: [{ required: true, message: '民族不能为空', trigger: 'blur' }],
        // employeeImage: [
        //   { required: true, message: '照片不能为空', trigger: 'blur' }
        // ],
        pregnantStatus: [{ required: true, message: '是否怀孕不能为空', trigger: 'blur' }],
        phone: [
          { required: true, message: '联系方式-手机号不能为空', trigger: 'blur' },
          { pattern: /^1\d{10}$/, message: '请输入正确的手机号', trigger: 'blur' }
        ],
        emergencyContact: [{ required: true, message: '联系方式-紧急联系人不能为空', trigger: 'blur' }],
        emergencyContactPhone: [{ required: true, message: '联系方式-紧急联系人电话不能为空', trigger: 'blur' }],
        hiredate: [{ required: true, message: '用工情况-入职时间(司龄)不能为空', trigger: 'blur' }],
        position: [{ required: true, message: '用工情况-职位(职能)不能为空', trigger: 'change' }],
        rank: [{ required: true, message: '用工情况-职务(职级)不能为空', trigger: 'change' }],
        socialSecurityNumber: [{ required: true, message: '用工情况-社保号不能为空', trigger: 'blur' }]
      },
      deptOptions: []
    }
  },
  filters: {},
  created() {},
  computed: {
    params() {
      return { fileName: this.form.jobNumber, type: 'aliyunoss', folder: 'account' }
    }
  },
  watch: {},
  mounted() {},
  methods: {
    /** 查询部门下拉树结构 */
    getTreeselect() {
      const query = {}
      treeselect(query).then(response => {
        this.deptOptions = response.data
      })
    },
    getfileName(fileName) {
      this.image = fileName
    },
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      if (this.deptOptions.length === 0) {
        this.getTreeselect()
      }
      this.formType = 1
      this.form = {
        id: null,
        deptId: null,
        jobNumber: null,
        name: null,
        sex: '0',
        birthday: null,
        identityCard: null,
        nation: null,
        marriage: '0',
        politics: null,
        firstWorkingTime: null,
        workState: null,
        retirementTime: null,
        workType: '0',
        sort: null,
        des: null,
        employeeImage: null,
        pregnantStatus: '0',
        nativePlace: null,
        currentPlace: null,
        habitation: null,
        phone: null,
        officePhone: null,
        email: null,
        internalMail: null,
        emergencyContact: null,
        emergencyContactPhone: null,
        graduateSchool: null,
        education: null,
        hiredate: null,
        position: null,
        rank: null,
        rankObtainTime: null,
        socialSecurityNumber: null
      }
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getEmployeeAccount(id).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.form.id !== undefined && this.form.id !== null) {
            updateEmployeeAccount(this.form).then(response => {
              this.$message.success('修改成功', 3)
              this.open = false
              this.$emit('ok')
            })
          } else {
            addEmployeeAccount(this.form).then(response => {
              this.$message.success('新增成功', 3)
              this.open = false
              this.$emit('ok')
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
