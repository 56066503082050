var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        width: "35%",
        "label-col": 4,
        "wrapper-col": 14,
        visible: _vm.open,
        title: _vm.formTitle,
      },
      on: { close: _vm.onClose },
    },
    [
      _c(
        "a-form-model",
        { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "所属机构", prop: "deptId" } },
            [
              _c("a-tree-select", {
                staticStyle: { width: "100%" },
                attrs: {
                  "dropdown-style": { maxHeight: "400px", overflow: "auto" },
                  "tree-data": _vm.deptOptions,
                  placeholder: "请选择",
                  replaceFields: _vm.replaceFields,
                  "tree-default-expand-all": "",
                },
                model: {
                  value: _vm.form.deptId,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "deptId", $$v)
                  },
                  expression: "form.deptId",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "工号", prop: "jobNumber" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入工号" },
                model: {
                  value: _vm.form.jobNumber,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "jobNumber", $$v)
                  },
                  expression: "form.jobNumber",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "姓名", prop: "name" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入姓名" },
                model: {
                  value: _vm.form.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "身份证", prop: "identityCard" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入身份证" },
                model: {
                  value: _vm.form.identityCard,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "identityCard", $$v)
                  },
                  expression: "form.identityCard",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "性别", prop: "sex" } },
            [
              _c(
                "a-radio-group",
                {
                  attrs: { disabled: "", "button-style": "solid" },
                  model: {
                    value: _vm.form.sex,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "sex", $$v)
                    },
                    expression: "form.sex",
                  },
                },
                _vm._l(_vm.sexOptions, function (d, index) {
                  return _c(
                    "a-radio-button",
                    { key: index, attrs: { value: d.dictValue } },
                    [_vm._v(_vm._s(d.dictLabel))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "生日", prop: "birthday" } },
            [
              _c("a-date-picker", {
                staticStyle: { width: "100%" },
                attrs: {
                  placeholder: "输入身份证后获取",
                  disabled: "",
                  "value-format": "YYYY-MM-DD HH:mm:ss",
                  format: "YYYY-MM-DD",
                  "allow-clear": "",
                },
                model: {
                  value: _vm.form.birthday,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "birthday", $$v)
                  },
                  expression: "form.birthday",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "民族", prop: "nation" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入民族" },
                model: {
                  value: _vm.form.nation,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "nation", $$v)
                  },
                  expression: "form.nation",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "婚姻", prop: "marriage" } },
            [
              _c(
                "a-radio-group",
                {
                  attrs: { "button-style": "solid" },
                  model: {
                    value: _vm.form.marriage,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "marriage", $$v)
                    },
                    expression: "form.marriage",
                  },
                },
                _vm._l(_vm.marriageOptions, function (d, index) {
                  return _c(
                    "a-radio-button",
                    { key: index, attrs: { value: d.dictValue } },
                    [_vm._v(_vm._s(d.dictLabel))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "政治面貌", prop: "politics" } },
            [
              _c(
                "a-select",
                {
                  attrs: { placeholder: "请选择政治面貌" },
                  model: {
                    value: _vm.form.politics,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "politics", $$v)
                    },
                    expression: "form.politics",
                  },
                },
                _vm._l(_vm.politicsOptions, function (d, index) {
                  return _c(
                    "a-select-option",
                    { key: index, attrs: { value: d.dictValue } },
                    [_vm._v(_vm._s(d.dictLabel))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "首次参加工作时间", prop: "firstWorkingTime" } },
            [
              _c("a-date-picker", {
                staticStyle: { width: "100%" },
                attrs: {
                  "value-format": "YYYY-MM-DD HH:mm:ss",
                  format: "YYYY-MM-DD",
                  "allow-clear": "",
                },
                model: {
                  value: _vm.form.firstWorkingTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "firstWorkingTime", $$v)
                  },
                  expression: "form.firstWorkingTime",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "在职状态", prop: "workState" } },
            [
              _c(
                "a-select",
                {
                  attrs: { placeholder: "请选择在职状态" },
                  model: {
                    value: _vm.form.workState,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "workState", $$v)
                    },
                    expression: "form.workState",
                  },
                },
                _vm._l(_vm.workStateOptions, function (d, index) {
                  return _c(
                    "a-select-option",
                    { key: index, attrs: { value: d.dictValue } },
                    [_vm._v(_vm._s(d.dictLabel))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "离职退休日期", prop: "retirementTime" } },
            [
              _c("a-date-picker", {
                staticStyle: { width: "100%" },
                attrs: {
                  "value-format": "YYYY-MM-DD HH:mm:ss",
                  format: "YYYY-MM-DD",
                  "allow-clear": "",
                },
                model: {
                  value: _vm.form.retirementTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "retirementTime", $$v)
                  },
                  expression: "form.retirementTime",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "用工类型", prop: "workType" } },
            [
              _c(
                "a-radio-group",
                {
                  attrs: { "button-style": "solid" },
                  model: {
                    value: _vm.form.workType,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "workType", $$v)
                    },
                    expression: "form.workType",
                  },
                },
                _vm._l(_vm.workTypeOptions, function (d, index) {
                  return _c(
                    "a-radio-button",
                    { key: index, attrs: { value: d.dictValue } },
                    [_vm._v(_vm._s(d.dictLabel))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "排序号", prop: "sort" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入排序号" },
                model: {
                  value: _vm.form.sort,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "sort", $$v)
                  },
                  expression: "form.sort",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "描述", prop: "des" } },
            [
              _c("a-input", {
                attrs: {
                  placeholder: "请输入内容",
                  type: "textarea",
                  "allow-clear": "",
                },
                model: {
                  value: _vm.form.des,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "des", $$v)
                  },
                  expression: "form.des",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "照片", prop: "employeeImage" } },
            [
              _c("file-upload", {
                attrs: { folder: _vm.params.folder, type: "image" },
                model: {
                  value: _vm.form.employeeImage,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "employeeImage", $$v)
                  },
                  expression: "form.employeeImage",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "是否怀孕", prop: "pregnantStatus" } },
            [
              _c(
                "a-radio-group",
                {
                  attrs: { "button-style": "solid" },
                  model: {
                    value: _vm.form.pregnantStatus,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "pregnantStatus", $$v)
                    },
                    expression: "form.pregnantStatus",
                  },
                },
                _vm._l(_vm.pregnantStatusOptions, function (d, index) {
                  return _c(
                    "a-radio-button",
                    { key: index, attrs: { value: d.dictValue } },
                    [_vm._v(_vm._s(d.dictLabel))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "居住情况-原籍", prop: "nativePlace" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入居住情况-原籍" },
                model: {
                  value: _vm.form.nativePlace,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "nativePlace", $$v)
                  },
                  expression: "form.nativePlace",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "居住情况-现籍", prop: "currentPlace" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入居住情况-现籍" },
                model: {
                  value: _vm.form.currentPlace,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "currentPlace", $$v)
                  },
                  expression: "form.currentPlace",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "居住情况-居住地", prop: "habitation" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入居住情况-居住地" },
                model: {
                  value: _vm.form.habitation,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "habitation", $$v)
                  },
                  expression: "form.habitation",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "联系方式-手机号", prop: "phone" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入联系方式-手机号" },
                model: {
                  value: _vm.form.phone,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "phone", $$v)
                  },
                  expression: "form.phone",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "联系方式-办公室电话", prop: "officePhone" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入联系方式-办公室电话" },
                model: {
                  value: _vm.form.officePhone,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "officePhone", $$v)
                  },
                  expression: "form.officePhone",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "联系方式-邮箱", prop: "email" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入联系方式-邮箱" },
                model: {
                  value: _vm.form.email,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "email", $$v)
                  },
                  expression: "form.email",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "联系方式-内部邮件", prop: "internalMail" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入联系方式-内部邮件" },
                model: {
                  value: _vm.form.internalMail,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "internalMail", $$v)
                  },
                  expression: "form.internalMail",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: { label: "联系方式-紧急联系人", prop: "emergencyContact" },
            },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入联系方式-紧急联系人" },
                model: {
                  value: _vm.form.emergencyContact,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "emergencyContact", $$v)
                  },
                  expression: "form.emergencyContact",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: "联系方式-紧急联系人电话",
                prop: "emergencyContactPhone",
              },
            },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入联系方式-紧急联系人电话" },
                model: {
                  value: _vm.form.emergencyContactPhone,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "emergencyContactPhone", $$v)
                  },
                  expression: "form.emergencyContactPhone",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "教育背景-毕业学校", prop: "graduateSchool" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入教育背景-毕业学校" },
                model: {
                  value: _vm.form.graduateSchool,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "graduateSchool", $$v)
                  },
                  expression: "form.graduateSchool",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "教育背景-学历", prop: "education" } },
            [
              _c(
                "a-select",
                {
                  attrs: { placeholder: "请选择教育背景-学历" },
                  model: {
                    value: _vm.form.education,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "education", $$v)
                    },
                    expression: "form.education",
                  },
                },
                _vm._l(_vm.educationOptions, function (d, index) {
                  return _c(
                    "a-select-option",
                    { key: index, attrs: { value: d.dictValue } },
                    [_vm._v(_vm._s(d.dictLabel))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "用工情况-入职时间(司龄)", prop: "hiredate" } },
            [
              _c("a-date-picker", {
                staticStyle: { width: "100%" },
                attrs: {
                  "value-format": "YYYY-MM-DD HH:mm:ss",
                  format: "YYYY-MM-DD",
                  "allow-clear": "",
                },
                model: {
                  value: _vm.form.hiredate,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "hiredate", $$v)
                  },
                  expression: "form.hiredate",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "用工情况-职位(职能)", prop: "position" } },
            [
              _c(
                "a-select",
                {
                  attrs: { placeholder: "请选择用工情况-职位(职能)" },
                  model: {
                    value: _vm.form.position,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "position", $$v)
                    },
                    expression: "form.position",
                  },
                },
                _vm._l(_vm.positionOptions, function (d, index) {
                  return _c(
                    "a-select-option",
                    { key: index, attrs: { value: d.dictValue } },
                    [_vm._v(_vm._s(d.dictLabel))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "用工情况-职务(职级)", prop: "rank" } },
            [
              _c(
                "a-select",
                {
                  attrs: { placeholder: "请选择用工情况-职务(职级)" },
                  model: {
                    value: _vm.form.rank,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "rank", $$v)
                    },
                    expression: "form.rank",
                  },
                },
                _vm._l(_vm.rankOptions, function (d, index) {
                  return _c(
                    "a-select-option",
                    { key: index, attrs: { value: d.dictValue } },
                    [_vm._v(_vm._s(d.dictLabel))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: "用工情况-当前职级获取时间",
                prop: "rankObtainTime",
              },
            },
            [
              _c("a-date-picker", {
                staticStyle: { width: "100%" },
                attrs: {
                  "value-format": "YYYY-MM-DD HH:mm:ss",
                  format: "YYYY-MM-DD",
                  "allow-clear": "",
                },
                model: {
                  value: _vm.form.rankObtainTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "rankObtainTime", $$v)
                  },
                  expression: "form.rankObtainTime",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: { label: "用工情况-社保号", prop: "socialSecurityNumber" },
            },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入用工情况-社保号" },
                model: {
                  value: _vm.form.socialSecurityNumber,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "socialSecurityNumber", $$v)
                  },
                  expression: "form.socialSecurityNumber",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "bottom-control" },
            [
              _c(
                "a-space",
                [
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.submitForm },
                    },
                    [_vm._v(" 保存 ")]
                  ),
                  _c(
                    "a-button",
                    { attrs: { type: "dashed" }, on: { click: _vm.cancel } },
                    [_vm._v(" 取消 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }